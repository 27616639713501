import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../../../../domain/services/configService';
import { ContainerMain } from '../../atoms/ContainerMain';
import { CardGridLeftRightLegacy } from '../CardGridLeftRightLegacy';
import { Ad } from '../../atoms/Ad';

export const RelatedContents = ({ contents, content }) => {
  const stickyAd = (
    <Ad
      key={`search_side__RelatedContents__${content.id}`}
      id="search_side__RelatedContents"
      content={content}
    />
  );

  return contents.length > 0 ? (
    <ContainerMain>
      <CardGridLeftRightLegacy
        header={t('Related content')}
        leftCardItems={contents}
        leftColumns={2}
        right={[stickyAd]}
      />
    </ContainerMain>
  ) : (
    <div />
  );
};

RelatedContents.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.PropTypes.objectOf(PropTypes.any).isRequired,
      gtmClickEvent: PropTypes.objectOf(PropTypes.any)
    })
  ).isRequired,
  content: PropTypes.objectOf(PropTypes.any).isRequired
};
